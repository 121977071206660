<template>
  <breadcrumb-page :list="breadcrumb_links"></breadcrumb-page>
  <div id="maincontent_container">
    <h2>General Information</h2>

    <div class = "alert alert-danger" v-if="error">
      {{error}}
    </div>
    <div v-if= "message" class=" alert alert-success">
      {{message}}
    </div>

    <form @submit.prevent="handleSubmission"> <!--Prevents default action from being taken-->
      <div class="row">
        <div class="col-md-2 fw-bold required">Legal Business Name</div>
        <div class="col-md-4">
          <input required type = "text" id = "businessName" class = "form-control" v-model = "business_name" placeholder="Same as business license">
        </div>
        <div class="col-md-2 fw-bold required">Owner</div>
        <div class="col-md-4">
          <input required type = "text" id = "owner" class = "form-control" v-model = "owner" placeholder="Owner">
        </div>
      </div>
      <br>
      <div class="row">
        <div class="col-md-2 fw-bold">Doing Business As</div>
        <div class="col-md-4">
          <input type = "text" id = "dba" class = "form-control" placeholder="(DBA)Doing Businses As" v-model= "dba">
        </div>
        <div class="col-md-2 fw-bold">President</div>
        <div class="col-md-4">
          <input type = "text" id = "president" class = "form-control" placeholder="President" v-model= "president">
        </div>
      </div>
      <br>
      <div class="row">
        <div class="col-md-2 fw-bold required">Shipping/Billing Address</div>
        <div class="col-md-4">
          <input required type = "text" id = "address" class = "form-control" v-model = "address" placeholder="No P.O. Boxes please">
        </div>
        <div class="col-md-2 fw-bold">CEO/CFO</div>
        <div class="col-md-4">
          <input required type = "text" id = "ceo_cfo" class = "form-control" v-model = "ceo_cfo" placeholder="CEO/CFO">
        </div>
      </div>
      <br>
      <div class="row">
        <div class="col-md-2 fw-bold required">City</div>
        <div class="col-md-4">
          <input required type = "text" id = "city" class = "form-control" v-model = "city" placeholder="City">
        </div>
        <div class="col-md-2 fw-bold required">Authorized Purchaser</div>
        <div class="col-md-4">
          <input required type = "text" id = "purchaser" class = "form-control" v-model = "purchaser" placeholder="Authorized Purchaser">
        </div>
      </div>
      <br>
      <div class="row">
        <div class="col-md-2 fw-bold required">State</div>
        <div class="col-md-4">
          <!-- <input required type = "text" id = "state" class = "form-control" v-model = "state" placeholder="Need to change this later"> -->
        <select class="form-control">
          <option value="AL">Alabama</option>
          <option value="AK">Alaska</option>
          <option value="AZ">Arizona</option>
          <option value="AR">Arkansas</option>
          <option value="CA">California</option>
          <option value="CO">Colorado</option>
          <option value="CT">Connecticut</option>
          <option value="DE">Delaware</option>
          <option value="DC">District Of Columbia</option>
          <option value="FL">Florida</option>
          <option value="GA">Georgia</option>
          <option value="HI">Hawaii</option>
          <option value="ID">Idaho</option>
          <option value="IL">Illinois</option>
          <option value="IN">Indiana</option>
          <option value="IA">Iowa</option>
          <option value="KS">Kansas</option>
          <option value="KY">Kentucky</option>
          <option value="LA">Louisiana</option>
          <option value="ME">Maine</option>
          <option value="MD">Maryland</option>
          <option value="MA">Massachusetts</option>
          <option value="MI">Michigan</option>
          <option value="MN">Minnesota</option>
          <option value="MS">Mississippi</option>
          <option value="MO">Missouri</option>
          <option value="MT">Montana</option>
          <option value="NE">Nebraska</option>
          <option value="NV">Nevada</option>
          <option value="NH">New Hampshire</option>
          <option value="NJ">New Jersey</option>
          <option value="NM">New Mexico</option>
          <option value="NY">New York</option>
          <option value="NC">North Carolina</option>
          <option value="ND">North Dakota</option>
          <option value="OH">Ohio</option>
          <option value="OK">Oklahoma</option>
          <option value="OR">Oregon</option>
          <option value="PA">Pennsylvania</option>
          <option value="RI">Rhode Island</option>
          <option value="SC">South Carolina</option>
          <option value="SD">South Dakota</option>
          <option value="TN">Tennessee</option>
          <option value="TX">Texas</option>
          <option value="UT">Utah</option>
          <option value="VT">Vermont</option>
          <option value="VA">Virginia</option>
          <option value="WA">Washington</option>
          <option value="WV">West Virginia</option>
          <option value="WI">Wisconsin</option>
          <option value="WY">Wyoming</option>
        </select>
        </div>
        <div class="col-md-2 fw-bold required">Purchaser Email</div>
        <div class="col-md-4">
          <input required type = "text" id = "purchaserEmail" class = "form-control" v-model = "purchaser_email" placeholder="Purchaser Email">
        </div>
      </div>
      <br>
      <div class="row">
        <div class="col-md-2 fw-bold required">ZipCode</div>
        <div class="col-md-4">
          <input required type = "text" id = "zipcode" class = "form-control" v-model = "zipcode" placeholder="ZipCode">
        </div>
        <div class="col-md-2 fw-bold">Account Payable Contact Name</div>
        <div class="col-md-4">
          <input required type = "text" id = "contactName" class = "form-control" v-model = "contact_name" placeholder="Account Payable Contact Name">
        </div>
      </div>
      <br>
      <div class="row">
        <div class="col-md-2 fw-bold required">Business Phone Number</div>
        <div class="col-md-4">
          <input required type = "text" id = "busPhoneNumber" class = "form-control" v-model = "bus_phone_number" placeholder="Please only contain numbers">
        </div>
        <div class="col-md-2 fw-bold">Account Payable E-mail Address</div>
        <div class="col-md-4">
          <input required type = "text" id = "accEmail" class = "form-control" v-model = "acc_email" placeholder="Account Payable E-mail Address">
        </div>
      </div>
      <br>
      <div class="row">
        <div class="col-md-2 fw-bold">Business Fax Number</div>
        <div class="col-md-4">
          <input required type = "text" id = "faxNumber" class = "form-control" v-model = "fax_number" placeholder="Please only contain numbers">
        </div>
        <div class="col-md-2 fw-bold">Business Website Address</div>
        <div class="col-md-4">
          <input required type = "text" id = "bus_WebAddress" class = "form-control" v-model = "bus_web_address" placeholder="Business Website Address">
        </div>
      </div>
      <br>
      <div class="row">
        <div class="col-md-2 fw-bold">Dun and Bradstreet Number</div>
        <div class="col-md-4">
          <input required type = "text" id = "dun_brad_number" class = "form-control" v-model = "dun_bradstreet_number" placeholder="Dun and Bradstreet Number(D&B Number)">
        </div>
        <div class="col-md-2 fw-bold required">Company Type</div>
        <div class="col-md-4">
          <select class="form-control">
            <option value="Sole_proprietor">Sole Proprietorship</option>
            <option value="Partnership">Partnership</option>
            <option value="LLC">LLC</option>
            <option value="Corporation">Corporation</option>
          </select>
        </div>
      </div>
      <br>
      <div class="row">
        <div class="col-md-2 fw-bold required">Federal Tax I.D. Number</div>
        <div class="col-md-4">
          <input required type = "text" id = "tax_id" class = "form-control" v-model = "tax_id" placeholder="Federal Tax I.D. Number">
        </div>
        <div class="col-md-2 fw-bold">Date Business was founded</div>
        <div class="col-md-4">
          <input required type = "date" id = "dateFounded" class = "form-control" v-model = "date_founded">
        </div>
      </div>
      <br>
      <h2>Owner Information</h2>
      <p> Please list all owners with 25% or greater ownership in business in order by greatest ownership percentage</p>
      <br>
      <label class="label label-primary">Owner 1</label>
      <br>
      <div class="row">
        <div class="col-md-2 fw-bold">Owner Name</div>
        <div class="col-md-4">
          <input required type = "text" class = "form-control" v-model = "owner1_name" placeholder="Owner Name">
        </div>
        <div class="col-md-2 fw-bold required">SSN</div>
        <div class="col-md-4">
          <input required type = "number" class = "form-control" v-model = "owner1_ssn" placeholder="9 digits SSN">
        </div>
      </div>
      <br>
      <div class="row">
        <div class="col-md-2 fw-bold">Ownership Percentage</div>
        <div class="col-md-4">
          <input required type = "number" class = "form-control" v-model = "owner1_percentage" placeholder="%">
        </div>
        <div class="col-md-2 fw-bold">Address</div>
        <div class="col-md-4">
          <input required type = "text" class = "form-control" v-model = "owner1_address" placeholder="Apt, Street">
        </div>
      </div>
      <br>
      <div id="hidden1" class="hideContainer">
      <label class="label label-primary">Owner 2</label>
      <br>
      <div class="row">
        <div class="col-md-2 fw-bold">Owner Name</div>
        <div class="col-md-4">
          <input required type = "text" class = "form-control" v-model = "owner2_name" placeholder="Owner Name">
        </div>
        <div class="col-md-2 fw-bold required">SSN</div>
        <div class="col-md-4">
          <input required type = "number" class = "form-control" v-model = "owner2_ssn" placeholder="9 digits SSN">
        </div>
      </div>
      <br>
      <div class="row">
        <div class="col-md-2 fw-bold">Ownership Percentage</div>
        <div class="col-md-4">
          <input required type = "number" class = "form-control" v-model = "owner2_percentage" placeholder="%">
        </div>
        <div class="col-md-2 fw-bold">Address</div>
        <div class="col-md-4">
          <input required type = "text" class = "form-control" v-model = "owner2_address" placeholder="Apt, Street">
        </div>
      </div>
      </div>
      <br>
      <div id="hidden2" class="hideContainer">
      <label class="label label-primary">Owner 3</label>
      <br>
      <div class="row">
        <div class="col-md-2 fw-bold">Owner Name</div>
        <div class="col-md-4">
          <input required type = "text" class = "form-control" v-model = "owner3_name" placeholder="Owner Name">
        </div>
        <div class="col-md-2 fw-bold required">SSN</div>
        <div class="col-md-4">
          <input required type = "number" class = "form-control" v-model = "owner3_ssn" placeholder="9 digits SSN">
        </div>
      </div>
      <br>
      <div class="row">
        <div class="col-md-2 fw-bold">Ownership Percentage</div>
        <div class="col-md-4">
          <input required type = "number" class = "form-control" v-model = "owner3_percentage" placeholder="%">
        </div>
        <div class="col-md-2 fw-bold">Address</div>
        <div class="col-md-4">
          <input required type = "text" class = "form-control" v-model = "owner3_address" placeholder="Apt, Street">
        </div>
      </div>
      <br>
      </div>
      <div id="hidden3" class="hideContainer">
      <label class="label label-primary">Owner 4</label>
      <br>
      <div class="row">
        <div class="col-md-2 fw-bold">Owner Name</div>
        <div class="col-md-4">
          <input required type = "text" class = "form-control" v-model = "owner4_name" placeholder="Owner Name">
        </div>
        <div class="col-md-2 fw-bold required">SSN</div>
        <div class="col-md-4">
          <input required type = "number" class = "form-control" v-model = "owner4_ssn" placeholder="9 digits SSN">
        </div>
      </div>
      <br>
      <div class="row">
        <div class="col-md-2 fw-bold">Ownership Percentage</div>
        <div class="col-md-4">
          <input required type = "number" class = "form-control" v-model = "owner4_percentage" placeholder="%">
        </div>
        <div class="col-md-2 fw-bold">Address</div>
        <div class="col-md-4">
          <input required type = "text" class = "form-control" v-model = "owner4_address" placeholder="Apt, Street">
        </div>
      </div>
      <br>
      </div>

       <div class="row">
        <div class="col-md-1"><button class = "btn btn-primary float-end" @click="showContain" type="submit">Add Owner</button></div>
        <div class="col-md-2">
          <button class = "btn btn-primary float-end" @click="hideContain">Delete Owner</button>
        </div>
      </div>
      <button class = "btn btn-primary float-end" @click="handleSubmit" id="send" type="submit">Save and Next</button>
    </form>
  </div>
</template>

<script>
import BreadcrumbPage from "../../components/BreadcrumbPage.vue"
import { ref } from 'vue'
import { getAuthAPI, getAPI } from '../../utils/axios-api'
import { useRoute, useRouter } from "vue-router";

export default {
  name: "AppSingUp",
  components: { BreadcrumbPage },
  setup() {
    const message = ref("")
    const error = ref(null)
    
    //get url parameters
    const route = useRoute();

    //get url parameters
    let credential = ""
    if(typeof route.query.cred != "undefined" && route.query.cred != ""){
      credential = route.query.cred
    }
    if (credential != "") {
        getAPI
        .get('/application/processform/?cred=' + credential)
        .then(response => {
            //check response data
            console.log("-----response data-----", response)
            
            data.value = response.data["results"]
            if (data.value.step == 0) step.value = 1
            else step.value = data.value.step
            
            
            
        })
        .catch(err => {  
            try {
              error.value = err.response.data["message"]
            }
            catch{
              error.value = err.response.data
              console.log("---------------111")
            }

            //show error message and redirect to returned user page.
            //setTimeout(()=>{router.push({ name: "ReturnUser", props: {id: 'asdfads'} })}, 2000);
            
        })
    }
    else {
        //if no credential is provided, redirect to sign up page
        //router.push({ name: "AppSignUp" });
    }

    

    const business_name = ref("")
    const owner = ref("")
    const dba = ref("")
    const president= ref("")
    const address= ref("")
    const ceo_cfo= ref("")
    const city= ref("")
    const purchaser= ref("")
    const state= ref("")
    const purchaser_email= ref("")
    const zipcode= ref("")
    const contact_name= ref("")
    const bus_phone_number= ref("")
    const pay_email= ref("")
    const fax_number= ref("")
    const bus_web_address= ref("")
    const dun_bradstreet_number= ref("")
    const company_type= ref("")
    const tax_id= ref("")
    const date_founded= ref("")

    const owner1_name= ref("")
    const owner1_ssn= ref("")
    const owner1_percentage= ref("")
    const owner1_address= ref("")

    const owner2_name= ref("")
    const owner2_ssn= ref("")
    const owner2_percentage= ref("")
    const owner2_address= ref("")

    const owner3_name= ref("")
    const owner3_ssn= ref("")
    const owner3_percentage= ref("")
    const owner3_address= ref("")

    const owner4_name= ref("")
    const owner4_ssn= ref("")
    const owner4_percentage= ref("")
    const owner4_address= ref("")

    //breadcrumb
    const breadcrumb_links = ref([])
    breadcrumb_links.value.push({text: "Home", link: "Home"})
    breadcrumb_links.value.push({text: "Become a reseller", link: "BecomeReseller"})
    breadcrumb_links.value.push({text: "Online Application", link: ""})

    // const catid = ref("");
    // var catid_param = "";
    // if (typeof route.query.catid != "undefined" && route.query.catid != "") {
    //   catid.value = route.query.catid;
    //   catid_param = "&catid=" + route.query.catid;

    function showContain() {
      if (document.getElementById('hidden1').getAttribute('class') == 'hideContainer') 
      {
        document.getElementById('hidden1').setAttribute('class', 'unhideContainer')
      }
      else if (document.getElementById('hidden2').getAttribute('class') == 'hideContainer') {
        document.getElementById('hidden2').setAttribute('class', 'unhideContainer')
      }
      else {
        document.getElementById('hidden3').setAttribute('class', 'unhideContainer')
      }
    }

    function hideContain() {
      if (document.getElementById('hidden3').getAttribute('class') == 'unhideContainer') 
      {
        document.getElementById('hidden3').setAttribute('class', 'hideContainer')
      }
      else if (document.getElementById('hidden2').getAttribute('class') == 'unhideContainer') {
        document.getElementById('hidden2').setAttribute('class', 'hideContainer')
      }
      else {
        document.getElementById('hidden1').setAttribute('class', 'hideContainer')
      }
    }

    const handleSubmit = () => {


      let payload = {
        "business_name": business_name.value,
        "owner": owner.value,
        "dba": dba.value,
        "president": president.value, 
        "address": address.value,
        "ceo_cfo": ceo_cfo.value, 
        "city":city.value, 
        "purchaser":purchaser.value, 
        "state":state.value, 
        "purchaser_email":purchaser_email.value,
        "zipcode":zipcode.value,
        "contact_name":contact_name.value,
        "bus_phone_number":bus_phone_number.value, 
        "pay_email":pay_email.value, 
        "fax_number":fax_number.value,  
        "bus_web_address":bus_web_address.value, 
        "dun_bradstreet_number": dun_bradstreet_number.value, 
        "company_type": company_type.value, 
        "tax_id": tax_id.value, 
        "date_founded":date_founded.value, 
        "owner1_name":owner1_name.value, 
        "owner1_ssn": owner1_ssn.value, 
        "owner1_percentage": owner1_percentage.value, 
        "owner1_address": owner1_address.value, 
        "owner2_name":owner2_name.value, 
        "owner2_ssn": owner2_ssn.value, 
        "owner2_percentage": owner2_percentage.value, 
        "owner2_address": owner2_address.value, 
        "owner3_name":owner3_name.value, 
        "owner3_ssn": owner3_ssn.value, 
        "owner3_percentage": owner3_percentage.value, 
        "owner3_address": owner3_address.value, 
        "owner4_name":owner4_name.value, 
        "owner4_ssn": owner4_ssn.value, 
        "owner4_percentage": owner4_percentage.value, 
        "owner4_address": owner4_address.value 
      }

    //   getAPI
    //   .post('/application/signup/', payload)
    //   .then(response => {
    //     //check response data
    //     //console.log("-----response data-----", response)
        
    //     message.value = response.data["message"],
    //     //message.value = "Success! A verification email with registration link has been sent to your email box."
    //     error.value = null
    //   })
    //   .catch(err => {
    // //     //check error response 
    //     console.log(err.response)
    //     error.value = err.response.data["email"]
    //     //error.value = "This email is used. Please enter a new email address."
    //    })
      
  }

     return {
         breadcrumb_links, 
         showContain, 
         hideContain, 
         handleSubmit, 
         business_name, 
         owner, 
         dba, 
         president, 
         address, 
         ceo_cfo, 
         city, 
         purchaser, 
         state, 
         purchaser_email, 
         zipcode, 
         contact_name, 
         bus_phone_number, 
         pay_email, 
         fax_number,
         bus_web_address, 
         dun_bradstreet_number, 
         company_type, 
         tax_id, 
         date_founded, 
         owner1_name, 
         owner1_ssn, 
         owner1_percentage, 
         owner1_address, 
         owner2_name, 
         owner2_ssn, 
         owner2_percentage, 
         owner2_address, 
         owner3_name, 
         owner3_ssn, 
         owner3_percentage, 
         owner3_address, 
         owner4_name, 
         owner4_ssn, 
         owner4_percentage, 
         owner4_address, 
         message, 
         error
     }
  }
}
</script>

<style>
  .required:after {
    content:" *";
    color: red;
  }

  .hideContainer {
    display: None;
  }

  .unhideContainer {
    display: block;
  }

  .label{
    display: inline; 
    padding: .2em .6em .3em;
    font-size: 75%; 
    font-weight: 700; 
    line-height: 1; 
    color: #fff; 
    text-align: center; 
    white-space: nowrap; 
    vertical-align: baseline; 
    border-radius: .25em; 
  }

  .label-primary {
    background-color: #337ab7;
  }



</style>